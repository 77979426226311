import { useState } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EmailSignup } from "../../services/login";
import WhiteLoadingAnimation from "../../assets/WhiteLoadingAnimation.svg";

function SignupForm() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
    });
    const [passwordError, setPasswordError] = useState(""); // State for password errors
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { firstName, lastName, email, password, confirmPassword } = formData;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    // Handle input fields when values change
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        // Validate password on input change
        if (name === "password") {
            if (!passwordRegex.test(value)) {
                setPasswordError(
                    "Password must be at least 8 characters long, include uppercase, lowercase, a number, and a special character."
                );
            } else {
                setPasswordError(""); // Clear the error if the password is valid
            }
        }
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle Form Submission
    const handleOnSubmit = async (e) => {
        e.preventDefault();

        if (!firstName || !lastName) {
            toast.error("First name and Last name are required");
            return;
        }

        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        if (!passwordRegex.test(password)) {
            setPasswordError(
                "Password must be at least 8 characters long, include uppercase, lowercase, a number, and a special character."
            );
            return;
        }

        try {
            setLoading(true); // Start the loading indicator

            // Call EmailSignup function
            const response = await EmailSignup(email, password, confirmPassword, firstName, lastName);

            // Show success message
            toast.success(response.message || "Signup successful.");

            // Check if user is already verified
            if (response.is_verified === true) {
                // Redirect to signin page if already verified
                navigate("/signin");
            } else {
                // Store email and redirect to verification page
                localStorage.setItem("signupEmail", response?.email);
                navigate("/verify-email");
            }

            // Reset form data
            setFormData({
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: "",
            });
        } catch (error) {
            const errorMessage = error.response?.data?.detail || "Something went wrong. Please try again.";
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };



    return (
        <form onSubmit={handleOnSubmit} className="mt-3 flex w-full flex-col gap-y-4 font-dmsans">
            {/* First Name and Last Name */}
            <div className="flex gap-x-4">
                {/* First Name */}
                <label className="w-1/2 space-y-2">
                    <p className="text-[14px] font-normal text-[#DBDBDB]">First name</p>
                    <input
                        required
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleOnChange}
                        placeholder="Enter first name"
                        autoComplete="off"
                        className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px] text-[18px]"
                    />
                </label>
                {/* Last Name */}
                <label className="w-1/2 space-y-2">
                    <p className="text-[14px] font-normal text-[#DBDBDB]">Last name</p>
                    <input
                        required
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleOnChange}
                        placeholder="Enter last name"
                        autoComplete="off"
                        className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px] text-[18px]"
                    />
                </label>
            </div>

            {/* Email */}
            <label className="w-full space-y-2">
                <p className="text-[14px] font-normal text-[#DBDBDB]">Email</p>
                <input
                    required
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleOnChange}
                    placeholder="Enter email address"
                    autoComplete="off"
                    className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px] text-[18px]"
                />
            </label>

            {/* Password */}
            <label className="relative space-y-2">
                <p className="text-[14px] font-normal text-[#DBDBDB]">Password</p>
                <div className="relative">
                    <input
                        required
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        onChange={handleOnChange}
                        placeholder="Enter password"
                        autoComplete="off"
                        className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] !pr-12 font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px] text-[18px]"
                    />
                    <span
                        onClick={() => setShowPassword((prev) => !prev)}
                        className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer text-[#A9A9A9]"
                    >
                        {!showPassword ? <LuEye fontSize={24} /> : <LuEyeOff fontSize={24} />}
                    </span>
                </div>
                {/* Display password error */}
                {passwordError && (
                    <p className="text-red-500 text-[12px] font-light mt-1">{passwordError}</p>
                )}
            </label>

            {/* Confirm Password */}
            <label className="relative space-y-2">
                <p className="text-[14px] font-normal text-[#DBDBDB]">Confirm password</p>
                <div className="relative">
                    <input
                        required
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleOnChange}
                        placeholder="Confirm password"
                        autoComplete="off"
                        className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] !pr-12 font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px] text-[18px]"
                    />
                    <span
                        onClick={() => setShowConfirmPassword((prev) => !prev)}
                        className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer text-[#A9A9A9]"
                    >
                        {!showConfirmPassword ? <LuEye fontSize={24} /> : <LuEyeOff fontSize={24} />}
                    </span>
                </div>
            </label>

            {/* Submit Button */}
            <button
                type="submit"
                className="mt-2 rounded-[6px] bg-[#39F6A3] w-full h-[50px] font-semibold text-[18px] text-[#1B1A22] flex justify-center items-center"
                disabled={loading}
            >
                {loading ? (
                    <span className="flex justify-center items-center h-full w-12">
                        <img src={WhiteLoadingAnimation} alt="Loading..." />
                    </span>
                ) : (
                    "Sign up"
                )}
            </button>
        </form>
    );
}

export default SignupForm;
