import { createSlice } from '@reduxjs/toolkit';
import { BREAKPOINTS } from "../services/emailUtils"

const getInitialState = () => {
  if (typeof window !== 'undefined' && window.matchMedia) {
    return {
      isOpen: !window.matchMedia(
        `${BREAKPOINTS.mobile}, ${BREAKPOINTS.mobileLarge}, ${BREAKPOINTS.tablet}`
      ).matches,
    };
  }
  return { isOpen: true }; // Default fallback for environments without matchMedia
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: getInitialState(),
  reducers: {
    toggleSidebar: (state) => {
      state.isOpen = !state.isOpen;
    },
    openSidebar: (state) => {
      state.isOpen = true;
    },
    closeSidebar: (state) => {
      state.isOpen = false;
    },
  },
});

export const { toggleSidebar, openSidebar, closeSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
