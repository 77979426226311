import React, { useState, useEffect } from "react";
import axios from "axios";
import { googleLogin } from "../../services/login";
import { FcGoogle } from "react-icons/fc";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingAnimation from "../../assets/LoadingAnimation.svg";
import { toast } from "react-toastify";

function GoogleSignInButton(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const API_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const isSigninPage = location.pathname.includes("/signin");


  // Function to handle Google Sign-In by fetching the authorization URL from the backend
  const handleGoogleSignIn = async () => {
    try {
      // Call the backend to get the Google OAuth authorization URL
      const response = await axios.get(`${API_URL}/v1/auth/google/login`);
      const authorizationUrl = response.data.authorization_url;

      // Redirect the user to the Google OAuth authorization URL
      window.location.href = authorizationUrl;
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
      setLoading(false);
    }
  };

  // Function to handle the OAuth callback (when the user is redirected back with the authorization code)
  const handleOAuthCallback = async (code) => {
    if (code) {
      setLoading(true);
      // codeProcessed.current = true;
      try {
        // Send the authorization code to your backend for token exchange
        await googleLogin(code);

        // Redirect to the homepage or another page after successful login
        navigate("/");
      } catch (error) {
        toast.error(`${error.response?.data?.detail || error.response?.data?.error || 'An unexpected error occurred'}`);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    // If a code exists and hasn't been processed yet, handle the OAuth callback
    if (code) {
      handleOAuthCallback(code);
    }
  }, [navigate]);

  return (
    <div className="flex w-full">
      <button
        id="google-login-btn"
        onClick={handleGoogleSignIn}
        className={`bg-[#FFFFFF]  gap-1 flex justify-center items-center h-[50px] md:h-[45px] w-full  lg:w-[418px] rounded-[6px] ${(props?.isDisabled) ? 'cursor-not-allowed opacity-50' : ''
          }`}
        disabled={props?.isDisabled || loading}
      >
        {loading ? (
          <span className="h-full w-12 lg:w-14 flex justify-center items-center rounded-md">
            <img src={LoadingAnimation} alt="loading..." />
          </span>

        ) : (
          <>
            <span className="h-full w-5 sm:w-6 flex justify-center items-center rounded-md">
              <FcGoogle className="w-[25px] h-[25px]" />
            </span>
            <div className="text-[14px]  font-semibold font-dmsans  text-[#1E293B]">
              Sign {isSigninPage ? "in" : "up"} with google
            </div>
          </>
        )}
      </button>
    </div>
  );
}
export default GoogleSignInButton;