import React, {useEffect } from "react";
import { LuStore } from "react-icons/lu";
import { LuChevronRightCircle, LuChevronLeftCircle } from "react-icons/lu";
import { Link,useLocation } from "react-router-dom";
import { BsGrid } from "react-icons/bs";
import ZenvaLogo from "../assets/SidebarAssets/ZenvaLogo.svg";
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar, openSidebar } from '../redux/sidebarSlice';
import MailLogo from "../assets/SidebarAssets/MailLogo.svg";

const AdminSidebar = () => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  useEffect(() => {
    dispatch(openSidebar());
  }, [dispatch]);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  return (
    <div
  className={`flex-shrink-0 top-0 z-10 fixed h-screen ${isSidebarOpen ? 'w-[236px]' : 'w-[80px]'} bg-[#F3F3F3] text-white transition-all ease-in-out duration-300 flex flex-col justify-between`}
  style={{
    background: '#0B0C16', // Updated to solid color
    border: '1.15px solid',
    borderImageSource: 'linear-gradient(180deg, #0E151C 0%, #21905F 100%)', // Keeps the border gradient if needed
  }}
>

    
          <div>
          <div className=" pt-4 mb-[50px] pl-[22px]">
          <div
            className={`flex items-center pt-[4px]  mr-2 justify-start w-full`}
          >
            <img src={isSidebarOpen ?MailLogo:ZenvaLogo} alt="" className="h-[30px] w-auto" />
            {/* <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-lg font-semibold pb-2`}>Zenva</span> */}
          </div>        </div>
          <ul className="space-y-2">
          
            <li className={`${isActive('/admin/dashboard-page') ? "border-r-2 border-primary-700 rounded-sm" : ""} `}>
              <Link
                to="/admin/dashboard-page"
                className={`flex rounded-[4px] items-center px-3 py-[10px] space-x-2 ml-2 mr-2  ${
                  isActive("/admin/dashboard-page")  
                    ? "bg-primary-700 bg-opacity-10 rounded-full text-primary-700 relative"
                    : "text-[#868CA2] rounded-full hover:bg-gray-600"
                }`}
              >
                <BsGrid size={24}/>
                <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Dashboard</span>
                
              </Link>
            </li>
            
            {/* <li className={`${isActive('/admin/user-page') || location.pathname.startsWith('/admin/user-page') ? "border-r-2 border-primary-700 rounded-sm" : ""} `}>
              <Link
                to="/admin/user-page"
                className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-2  ${
                  isActive("/admin/user-page") ||location.pathname.startsWith('/admin/user-page')
                    ? "bg-primary-700 bg-opacity-10 rounded-full text-primary-700 relative"
                    : "text-[#868CA2] rounded-full hover:bg-gray-600"
                }`}
              >
                <FaRegUser size={22} />
                <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>User Management</span>
                
              </Link>
            </li> */}

            <li className={`${isActive('/admin/store-page') || location.pathname.startsWith('/admin/store-page') ? "border-r-2 border-primary-700 rounded-sm" : ""} `}>
              <Link
                to="/admin/store-page"
                className={`flex items-center px-3 py-[10px] space-x-2  ml-2 mr-2  ${
                  isActive("/admin/store-page") || location.pathname.startsWith('/admin/store-page')
                    ? "bg-primary-700 bg-opacity-10 rounded-full text-primary-700 relative"
                    : "text-[#868CA2] rounded-full hover:bg-gray-600"
                }`}
              >
                <LuStore size={24} />
                <span className={`${isSidebarOpen ? 'block' : 'hidden'} text-base font-semibold`}>Store Management</span>
                
              </Link>
            </li>
            {/* <li className={`${isActive('/admin/settings-page') ? "border-r-2 border-primary-700 rounded-sm" : ""} `}>
              <Link
                to="/admin/settings-page"
                className={`flex items-center px-3 py-[10px] space-x-2 ml-2 mr-5  ${
                  isActive("/admin/settings-page")
                    ? "bg-primary-700 bg-opacity-10 rounded-md text-primary-700  relative"
                    : "text-secondary-300   rounded-md hover:bg-gray-300"
                }`}
              >
                <IoSettingsOutline size={24} />
                <span className={textStyles}>Settings</span>
                
              </Link>
            </li> */}
            
          </ul>

        </div>

        <div className="flex flex-grow items-end">
        <div className={`absolute bottom-4 ${isSidebarOpen ? 'right-5' : 'right-1/2 transform translate-x-1/2'}`}>
          {isSidebarOpen ? (
            <LuChevronLeftCircle
              size={38}
              className="cursor-pointer text-[#D7FDED] hover:text-gray-400"
              style={{ strokeWidth: 0.6 }} // Adjust stroke width here
              onClick={handleToggleSidebar}
            />
          ) : (
            <LuChevronRightCircle
              size={38}
              className="cursor-pointer text-[#D7FDED] hover:text-gray-400"
              style={{ strokeWidth: 0.6 }} // Adjust stroke width here
              onClick={handleToggleSidebar}
            />
          )}
        </div>
      </div>
      </div>
  );
};

export default AdminSidebar;
