import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { withLDProvider } from "launchdarkly-react-client-sdk"

// Non-lazy routes for critical UI that needs to load instantly
import Signin from "./pages/AuthPages/Signin";
import Signup from "./pages/AuthPages/Signup";
import ForgotPassword from "./pages/AuthPages/ForgotPassword";
import ResetPassword from "./pages/AuthPages/ResetPassword";
import VerifyEmail from "./pages/AuthPages/VerifyEmail";
import Layout from "./layouts/ContentLayout";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./layouts/PrivateLayout";
import AdminLayout from "./layouts/AdminLayout";
import ProtectedAdminRoutes from "./layouts/ProtectedAdminRoutes";

// Lazy-loaded routes for better performance and optimized initial load
const Home = lazy(() => import("./pages/Home"));
const DraftPage = lazy(() => import("./pages/DraftPage"));
const IntegrationPage = lazy(() => import("./pages/IntegrationPage"));
const ExamplesPage = lazy(() => import("./pages/ExamplesPage"));
const ExampleDetailPage = lazy(() => import("./pages/ExamplePages/ExampleDetailPage"));
const CreateExamplesPage = lazy(() => import("./pages/ExamplePages/CreateExamplesPage"));
const AccountPage = lazy(() => import("./pages/AccountPage"));
const DraftDetailPage = lazy(() => import("./pages/DraftDetailPage"));
const ApplicationSetup = lazy(() => import("./pages/OnboardingPages/ApplicationSetup"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const AdminHome = lazy(() => import("./pages/AdminPages/AdminHome"));
const AdminDashboard = lazy(() => import("./pages/AdminPages/AdminDashboard"));
const AdminStorePage = lazy(() => import("./pages/AdminPages/AdminStorePage"));
const AdminStorePageDetails = lazy(() =>
  import("./pages/AdminPages/AdminStorePageDetails")
);
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const CreatePolicyPage = lazy(() => import("./pages/CreatePolicyPage"));
const Error502Page = lazy(() => import("./pages/Error502Page"));
const DocumentsPage = lazy(() => import("./pages/DocumentsPage"));
const SelectStore = lazy(() => import("./pages/OnboardingPages/SelectStore"));
const AddClient = lazy(() => import("./pages/OnboardingPages/AddClient"));
const SettingsPage = lazy(() => import("./pages/SettingsPage/SettingPage"));
const CreateTemplatePage = lazy(() => import("./pages/SettingsPage/Sections/CreateTemplate"));

import { ErrorBoundary } from "@sentry/react";
// Wrapper for ErrorBoundary and Suspense
const WrappedComponent = ({ children }) => (
  <Suspense fallback={<div></div>}>
    <ErrorBoundary fallback={<PageNotFound text="An unexpected error occurred!" />}>
      {children}
    </ErrorBoundary>
  </Suspense>
);

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-email" element={<VerifyEmail />} />

          <Route path="/" element={<PrivateRoute isAdminRoute={false} />}>
            <Route element={<Layout />}>
              <Route index element={
                <WrappedComponent>
                  <Home />
                </WrappedComponent>

              } />
              <Route
                path="/add-client"
                element={
                  <WrappedComponent>
                    <AddClient />
                  </WrappedComponent>
                }
              />
              <Route
                path="/select-store"
                element={
                  <WrappedComponent>
                    <SelectStore />
                  </WrappedComponent>
                }
              />
              <Route
                path="/emails-page"
                element={
                  <WrappedComponent>
                    <DraftPage />
                  </WrappedComponent>
                }
              />
              <Route
                path="/email-page/detail/:id"
                element={
                  <WrappedComponent>
                    <DraftDetailPage />
                  </WrappedComponent>
                }
              />
              <Route path="/document-page"
                element={
                  <WrappedComponent>
                    <DocumentsPage />
                  </WrappedComponent>
                } />
              <Route
                path="/document-page/create-policy/:id?"
                element={
                  <WrappedComponent>
                    <CreatePolicyPage />
                  </WrappedComponent>
                }
              />
              <Route
                path="/integration-page"
                element={
                  <WrappedComponent>
                    <IntegrationPage />
                  </WrappedComponent>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <Dashboard />
                }
              />
              <Route
                path="/examples-page"
                element={
                  <WrappedComponent>
                    <ExamplesPage />
                  </WrappedComponent>
                }
              />
              <Route
                path="/create-example/:id?"
                element={
                  <WrappedComponent>
                    <CreateExamplesPage />
                  </WrappedComponent>
                }
              />
              <Route
                path="/example/:id?"
                element={
                  <WrappedComponent>
                    <ExampleDetailPage />
                  </WrappedComponent>
                }
              />

              <Route
                path="/settings-page/auto-reply"
                element={
                  <Suspense fallback={<div></div>}>
                    <SettingsPage />
                  </Suspense>
                }
              />
              <Route
                path="/settings-page/auto-reply/edit-template/:id"
                element={
                  <Suspense fallback={<div></div>}>
                    <CreateTemplatePage />
                  </Suspense>
                }
              />
              <Route
                path="/settings-page/auto-reply/create-template"
                element={
                  <Suspense fallback={<div></div>}>
                    <CreateTemplatePage />
                  </Suspense>
                }
              />
              <Route
                path="/settings-page/signature"
                element={
                  <Suspense fallback={<div></div>}>
                    <SettingsPage />
                  </Suspense>
                }
              />
              <Route
                path="/settings-page/signature/edit-template/:id"
                element={
                  <Suspense fallback={<div></div>}>
                    <CreateTemplatePage />
                  </Suspense>
                }
              />
              <Route
                path="/settings-page/signature/create-template"
                element={
                  <Suspense fallback={<div></div>}>
                    <CreateTemplatePage />
                  </Suspense>
                }
              />
              <Route
                path="/accounts-page"
                element={
                  <WrappedComponent>
                    <AccountPage />
                  </WrappedComponent>
                }
              />
              <Route
                path="/welcome-page/:status?"
                element={
                  <WrappedComponent>
                    <ApplicationSetup />
                  </WrappedComponent>
                }
              />
            </Route>
          </Route>
          <Route path="/admin" element={<ProtectedAdminRoutes />}>
            <Route element={<AdminLayout />}>
              <Route path="" element={
                <WrappedComponent>
                  <AdminHome />
                </WrappedComponent>
              } />
              <Route
                path="dashboard-page"
                element={
                  <WrappedComponent>
                    <AdminDashboard />
                  </WrappedComponent>
                }
              />
              <Route
                path="store-page"
                element={
                  <WrappedComponent>
                    <AdminStorePage />
                  </WrappedComponent>
                }
              />
              <Route
                path="store-page/details/:id"
                element={
                  <WrappedComponent>
                    <AdminStorePageDetails />
                  </WrappedComponent>
                }
              />
            </Route>
          </Route>
          <Route
            path="/502-error"
            element={
              <WrappedComponent>
                <Error502Page />
              </WrappedComponent>
            }
          />
          <Route
            path="/error"
            element={
              <WrappedComponent>
                <ErrorPage />
              </WrappedComponent>
            }
          />
          <Route
            path="*"
            element={
              <WrappedComponent>
                <PageNotFound />
              </WrappedComponent>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App = withLDProvider({
  clientSideID: process.env.LDCLIENT_ID,
  context: {
    "kind": "user",
    "key": "user-key-123abc",
    "name": "Sandy Smith",
    "email": "sandy@example.com"
  }
})(App);
