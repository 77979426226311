import { useState } from "react"
import { LuEye, LuEyeOff } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom"
import WhiteLoadingAnimation from "../../assets/WhiteLoadingAnimation.svg";
import { EmailSignin } from "../../services/login";
import { toast } from "react-toastify";


function SigninForm() {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    })

    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false);

    const { email, password } = formData

    const handleOnChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }))
    }

    // Handle Form Submission
    const handleOnSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            // Mock API call
            const response = await EmailSignin(email, password);
    
            if (response?.is_verified === "False") {
                // Redirect to email verification page
                localStorage.setItem("signupEmail", response?.email);
                navigate("/verify-email", { state: { from: "/signin" } });
            } else if (!response?.last_login || response?.last_login === null) {
                // Redirect to Add Client if there is no last login
                toast.success("Welcome! to Zenva AI.");
                
                navigate("/");
            } else {
                // Redirect to dashboard or home
                toast.success("Signin successful!");
                navigate("/");
            }
    
            // Reset form
            setFormData({
                email: "",
                password: "",
            });
        } catch (error) {
            toast.error(`${error.response?.data?.detail || "Signin failed. Please try again."}`);
        } finally {
            setLoading(false);
        }
    };
    
      

    return (
        <>
            <form
                onSubmit={handleOnSubmit}
                className="mt-3 flex w-full flex-col gap-y-4 font-dmsans"
            >
                <label className="w-full space-y-2">
                    <p className="text-[14px] font-normal text-[#DBDBDB]">
                        Email
                    </p>
                    <input
                        required
                        type="text"
                        name="email"
                        value={email}
                        onChange={handleOnChange}
                        placeholder="Enter email address"
                        autoComplete="off"
                        className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] text-[18px] font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px]"
                    />
                </label>
                <label className="relative space-y-2">
                    <div className="w-full flex items-center justify-between">
                        <p className="text-[14px] font-normal text-[#DBDBDB]">
                            Password
                        </p>
                        <Link to="/forgot-password">
                            <p className="text-xs text-[#337EFF] font-normal">
                                Forgot password?
                            </p>
                        </Link>
                    </div>

                    <div className="relative">
                        <input
                            required
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={password}
                            onChange={handleOnChange}
                            placeholder="Enter password"
                            autoComplete="off"
                            className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] !pr-12 font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px] text-[18px]"
                        />
                        <span
                            onClick={() => setShowPassword((prev) => !prev)}
                            className="absolute inset-y-0 right-3 flex items-center z-[10] cursor-pointer text-[#A9A9A9]"
                        >
                            {!showPassword ? (
                                <LuEye fontSize={24} /> // Eye icon for showing the password
                            ) : (
                                <LuEyeOff fontSize={24} /> // EyeOff icon for hiding the password
                            )}
                        </span>
                    </div>
                </label>

                <button
                    type="submit"
                    className="mt-2 rounded-[6px] bg-[#39F6A3] w-full h-[50px] font-semibold text-[18px] text-[#1B1A22] flex justify-center items-center"
                    disabled={loading}
                >
                    {loading ? (
                        <span className="flex justify-center items-center h-full w-12 lg:w-14">
                            <img src={WhiteLoadingAnimation} alt="Loading..." />
                        </span>
                    ) : (
                        "Log in")}
                </button>
            </form>
        </>
    )
}

export default SigninForm