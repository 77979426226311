import { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import Template from "../../components/SignInPageComponents/Template";
import LoadingAnimation from "../../assets/LoadingAnimation.svg";
import VerifyEmailImage from "../../assets/AuthPagesAssets/VerifyEmail.svg";
import { toast } from "react-toastify";
import { verifyEmailAPI } from "../../services/login";
import axios from "axios";

function VerifyEmail() {
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [emailVerified, setEmailVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [resendText, setResendText] = useState("Resend Email"); // Text for Resend Button
    const API_URL = process.env.REACT_APP_API_URL;

    // Retrieve email from localStorage on component load
    useEffect(() => {
        const storedEmail = localStorage.getItem("signupEmail");
        if (storedEmail) {
            setEmail(storedEmail);
        } else {
            toast.error("No email found. Please sign up again.");
        }
    }, []);


    // Call Resend Email API if previous route was "/signin" and email is available
    useEffect(() => {
        if (location.state?.from === "/signin" && email) {
            handleResendEmail();
        }
    }, [location.state?.from, email]);

    // Handle form submission
    const handleOnSubmit = async (e) => {
        e.preventDefault();

        if (!verificationCode) {
            toast.error("Please enter the verification code.");
            return;
        }

        try {
            setLoading(true);

            // Call the API to verify email
            const response = await verifyEmailAPI(email, verificationCode);

            setEmailVerified(true);
            toast.success(response.message || "Email verified successfully!");
            // Clear localStorage
            localStorage.removeItem("signupEmail");
        } catch (error) {
            toast.error(`${error.response?.data?.detail || "Failed to verify email."}`);
        } finally {
            setLoading(false);
        }
    };

    // Resend verification email
    const handleResendEmail = async () => {
        try {
            setResendLoading(true);
            const formData = new FormData();
            formData.append("email", email);

            // Call the Resend Email API
            await axios.post(`${API_URL}/v1/auth/resend-verification-code`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            setResendText(`Verification email resent to ${email}`); // Update button text
            toast.success("Verification email has been resent!");
        } catch (error) {
            toast.error(`${error.response?.data?.detail || "Failed to resend verification email."}`);
        } finally {
            setResendLoading(false);
        }
    };

    return (
        <Template>
            <div className="flex flex-col justify-center items-start w-[94%] sm:w-[90%] lg:w-[418px] mx-auto space-y-4 font-dmsans">
                {/* Success Image */}
                {emailVerified && (
                    <div className="flex justify-center items-center my-4 w-full">
                        <img src={VerifyEmailImage} alt="Verified" className="h-40 w-50 object-contain" />
                    </div>
                )}
                <h1 className="font-semibold text-[#FFFFFF] text-[24px] lg:text-[30px]">
                    {!emailVerified ? "Verify your email" : "Email verified!"}
                </h1>
                <p className="text-[16px] lg:text-[18px] text-[#ABABAB] font-light">
                    {!emailVerified
                        ? `We have sent a verification code to ${email}.`
                        : `Your email ${email} has been successfully verified.`}
                </p>

                {/* Verification Form */}
                {!emailVerified && (
                    <form onSubmit={handleOnSubmit} className="w-full space-y-4">
                        {/* Verification Code Input */}
                        <label className="my-2 w-full">
                            <p className="text-[14px] font-normal text-[#DBDBDB]">Verification Code</p>
                            <input
                                required
                                type="text"
                                name="verificationCode"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                placeholder="Enter verification code"
                                autoComplete="off"
                                className="bg-[#1C1C1C] w-full h-[50px] rounded-md border-[0.2px] border-[#F3F3F366] text-[#DBDBDB] px-[10px] text-[18px] font-medium placeholder:font-extralight placeholder:text-[#8E8E8E] placeholder:text-[14px] mt-1"
                            />
                        </label>

                        {/* Submit Button */}
                        <button
                            type="submit"
                            disabled={loading}
                            className="w-full rounded-[6px] h-[50px] text-[16px] lg:text-[18px] font-semibold bg-[#F3F3F3] text-[#1B1A22] flex justify-center items-center"
                        >
                            {loading ? (
                                <img src={LoadingAnimation} alt="Loading..." className="h-full w-12 lg:w-14" />
                            ) : (
                                "Verify Email"
                            )}
                        </button>

                        {/* Resend Email Button */}
                        <button
                            type="button"
                            disabled={resendLoading}
                            onClick={handleResendEmail}
                            className="w-full rounded-[6px] h-[50px] text-[16px] lg:text-[18px] font-semibold bg-[#1C1C1C] text-[#F3F3F3] flex justify-center items-center mt-3 border border-[#F3F3F366] hover:bg-[#333333] transition-colors"
                        >
                            {resendLoading ? (
                                <img src={LoadingAnimation} alt="Loading..." className="h-full w-12 lg:w-14" />
                            ) : "Resend Email"}
                        </button>
                    </form>
                )}

                {/* Back to Login */}
                <div className="flex items-center justify-between text-[#DBDBDB] text-[16px] lg:text-[18px] font-dmsans mt-3">
                    <Link to="/signin">
                        <p className="flex items-center gap-x-2">
                            <BiArrowBack /> Login
                        </p>
                    </Link>
                </div>
            </div>
        </Template>
    );
}

export default VerifyEmail;
