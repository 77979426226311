import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../services/axiosInstance"; // Axios instance for API calls
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  FaPaperPlane,
} from "react-icons/fa";
import { HiArrowUp, HiArrowDown } from "react-icons/hi";
import { FiMail } from "react-icons/fi";
import { LuMailCheck } from "react-icons/lu";
import { PiPencilSimpleLineBold } from "react-icons/pi";

const Dashboard = () => {
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [availableYears, setAvailableYears] = useState([]);
  const [draftStats, setDraftStats] = useState({});
  const [topCustomers, setTopCustomers] = useState([]);
  const [averageTimes, setAverageTimes] = useState({});
  const [yearlyData, setYearlyData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Predefined months with default values
  const defaultYearlyData = [
    { month: "January", Refund: 0, "Order Update": 0, "Address Change": 0 },
    { month: "February", Refund: 0, "Order Update": 0, "Address Change": 0 },
    { month: "March", Refund: 0, "Order Update": 0, "Address Change": 0 },
    { month: "April", Refund: 0, "Order Update": 0, "Address Change": 0 },
    { month: "May", Refund: 0, "Order Update": 0, "Address Change": 0 },
    { month: "June", Refund: 0, "Order Update": 0, "Address Change": 0 },
    { month: "July", Refund: 0, "Order Update": 0, "Address Change": 0 },
    { month: "August", Refund: 0, "Order Update": 0, "Address Change": 0 },
    { month: "September", Refund: 0, "Order Update": 0, "Address Change": 0 },
    { month: "October", Refund: 0, "Order Update": 0, "Address Change": 0 },
    { month: "November", Refund: 0, "Order Update": 0, "Address Change": 0 },
    { month: "December", Refund: 0, "Order Update": 0, "Address Change": 0 },
  ];

  const formatTime = (totalSeconds) => {
    const days = Math.floor(totalSeconds / (24 * 3600)); // Calculate days
    const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600); // Calculate hours
    const minutes = Math.round((totalSeconds % 3600) / 60); // Calculate minutes
    // Round seconds to 2 decimal places

    // Format the string
    return `${days}d ${hours}h ${minutes}m`;
  };

  // Fetch available years when the component mounts
  useEffect(() => {
    const fetchAvailableYears = async () => {
      try {
        const response = await axiosInstance.get("/v1/store/dashboard/available-years");
        setAvailableYears(response.data || []);
      } catch (error) {
        console.error("Error fetching available years:", error);
        setAvailableYears([new Date().getFullYear()]); // Fallback
      }
    };

    fetchAvailableYears();
  }, []);

  // Fetch dashboard data based on the selected year
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);

        // Fetch data for the selected year
        const [
          draftStatsResponse,
          topCustomersResponse,
          averageTimesResponse,
          yearlyDataResponse,
        ] = await Promise.all([
          axiosInstance.get(`/v1/store/dashboard/draft-stats?year=${selectedYear}`),
          axiosInstance.get("/v1/store/dashboard/top-customers"),
          axiosInstance.get("/v1/store/dashboard/average-times"),
          axiosInstance.get(`/v1/store/dashboard/action-stats?timeframe=yearly&year=${selectedYear}`),
        ]);

        // Update states
        setDraftStats(draftStatsResponse.data || {});
        setTopCustomers(topCustomersResponse.data || []);
        setAverageTimes(averageTimesResponse.data || []);

        // Format data for the bar chart
        const apiYearlyData = yearlyDataResponse.data || [];
        const formattedYearlyData = defaultYearlyData.map((defaultMonth) => {
          const monthData = apiYearlyData.find(
            (item) => item.time_period === defaultMonth.month
          );
          return {
            month: defaultMonth.month,
            Refund: monthData?.refund_count || 0,
            "Order Update": monthData?.update_address_count || 0,
            "Address Change": monthData?.order_change_count || 0,
          };
        });

        setYearlyData(formattedYearlyData);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setDraftStats({});
        setTopCustomers([]);
        setAverageTimes({});
        setYearlyData(defaultYearlyData);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [selectedYear]); // Re-fetch data when the year changes

  return (
    <div
      className={`py-2 font-dmsans overflow-y-auto bg-[#F9F9F9] ${isSidebarOpen ? "lg:ml-[220px] xl:ml-[230px]" : "lg:ml-[80px]"
        } transition-all duration-300`}
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="font-dmsans font-bold text-[24px] sm:text-[28px] lg:text-[32px] text-black text-left leading-[31.25px] pl-4 lg:pl-6 mb-4">
          Dashboard
        </div>

        {/* Dashboard Metrics */}
        <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 px-4">
          {/* Total Drafts */}
          <div className="bg-[#0C0D17] text-gray-300 shadow-lg rounded-xl p-3 flex flex-col justify-between relative">
            <FiMail
              className="absolute top-7 right-3 text-3xl text-[#39F6A3]"
            />
            <h2 className="text-base font-semibold">TOTAL DRAFT</h2>
            {loading ? (
              <div className="animate-pulse">
                <div className="h-4 bg-gray-300 rounded w-1/3 mb-2"></div>
              </div>
            ) : (
              <p className="text-2xl font-bold mt-2 text-white">
                {draftStats.total_drafts || 0}
              </p>
            )}
            <div className="h-[1px] w-full bg-gradient-to-r from-white to-transparent mt-2 mb-3"></div>
            <div className="flex items-center">
              <HiArrowUp className="text-lg mr-1 text-[#59C681]" />
              {loading ? (
                <div className="animate-pulse h-4 bg-gray-300 rounded w-1/4"></div>
              ) : (
                <p className="text-sm">
                  <span className="text-green-400 font-bold">
                    {draftStats.percentage_changes?.total_drafts_change || 0}%
                  </span>{" "}
                  <span className="text-white">than last week</span>
                </p>
              )}
            </div>
          </div>

          {/* Drafts Sent */}
          <div className="bg-[#0C0D17] text-gray-300 shadow-lg rounded-xl p-3 flex flex-col justify-between relative">
            <FaPaperPlane
              className="absolute top-7 right-3 text-3xl text-[#39F6A3]"
            />
            <h2 className="text-base font-semibold">DRAFT SENT</h2>
            {loading ? (
              <div className="animate-pulse">
                <div className="h-4 bg-gray-300 rounded w-1/3 mb-2"></div>
              </div>
            ) : (
              <p className="text-2xl font-bold mt-2 text-white">
                {draftStats.drafts_sent || 0}
              </p>
            )}
            <div className="h-[1px] w-full bg-gradient-to-r from-white to-transparent mt-2 mb-3"></div>
            <div className="flex items-center">
              <HiArrowUp className="text-lg mr-1 text-[#59C681]" />
              {loading ? (
                <div className="animate-pulse h-4 bg-gray-300 rounded w-1/4"></div>
              ) : (
                <p className="text-sm">
                  <span className="text-green-400 font-bold">
                    {draftStats.percentage_changes?.drafts_sent_change || 0}%
                  </span>{" "}
                  <span className="text-white">than last week</span>
                </p>
              )}
            </div>
          </div>

          {/* Drafts Edited */}
          <div className="bg-[#0C0D17] text-gray-300 shadow-lg rounded-xl p-3 flex flex-col justify-between relative">
            <PiPencilSimpleLineBold
              className="absolute top-7 right-3 text-3xl text-[#39F6A3]"
            />
            <h2 className="text-base font-semibold">DRAFTS EDITED</h2>
            {loading ? (
              <div className="animate-pulse">
                <div className="h-4 bg-gray-300 rounded w-1/3 mb-2"></div>
              </div>
            ) : (
              <p className="text-2xl font-bold mt-2 text-white">
                {draftStats.drafts_edited || 0}
              </p>
            )}
            <div className="h-[1px] w-full bg-gradient-to-r from-white to-transparent mt-2 mb-3"></div>
            <div className="flex items-center">
              <HiArrowUp className="text-lg mr-1 text-[#59C681]" />
              {loading ? (
                <div className="animate-pulse h-4 bg-gray-300 rounded w-1/4"></div>
              ) : (
                <p className="text-sm">
                  <span className="text-green-400 font-bold">
                    {Math.abs(draftStats.percentage_changes?.edited_drafts_change || 0)}%
                  </span>{" "}
                  <span className="text-white">than last week</span>
                </p>
              )}
            </div>
          </div>

          {/* Drafts Deleted */}
          <div className="bg-[#0C0D17] text-gray-300 shadow-lg rounded-xl p-3 flex flex-col justify-between relative">
            <LuMailCheck
              className="absolute top-7 right-3 text-3xl text-[#39F6A3]"
            />
            <h2 className="text-base font-semibold">DRAFTS DELETED</h2>
            {loading ? (
              <div className="animate-pulse">
                <div className="h-4 bg-gray-300 rounded w-1/3 mb-2"></div>
              </div>
            ) : (
              <p className="text-2xl font-bold mt-2 text-white">
                {draftStats.drafts_deleted || 0}
              </p>
            )}
            <div className="h-[1px] w-full bg-gradient-to-r from-white to-transparent mt-2 mb-3"></div>
            <div className="flex items-center">
              <HiArrowDown
                className="text-lg mr-1 text-[#D04848]"
              />
              {loading ? (
                <div className="animate-pulse h-4 bg-gray-300 rounded w-1/4"></div>
              ) : (
                <p className="text-sm">
                  <span className="text-red-400 font-bold">
                    {draftStats.percentage_changes?.drafts_deleted_change || 0}%
                  </span>{" "}
                  <span className="text-white">than last week</span>
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Draft Analysis Section */}
        <div className="mt-8 mx-4 px-4 sm:px-6 lg:px-8 bg-white shadow-lg rounded-xl pt-6 overflow-x-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold text-black">Draft analysis</h2>
            <div className="relative">
              <select
                className="text-sm font-bold text-black px-2 py-1 rounded-lg border border-gray-300"
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                style={{
                  fontFamily: 'DM Sans',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '21px',
                  textAlign: 'left',
                  textUnderlinePosition: 'from-font',
                  textDecorationSkipInk: 'none',
                }}
              >
                {availableYears.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="h-[2px] w-full bg-gradient-to-r from-gray-300 to-transparent mb-2"></div>

          {/* Updated Legend positioning */}
          <div className="flex justify-start items-center gap-4 sm:gap-6 px-2 mb-2 overflow-x-auto pb-1">
            <div className="flex items-center gap-2 whitespace-nowrap">
              <div className="w-4 h-4 rounded bg-black"></div>
              <p className="text-sm font-medium text-gray-600">Refund</p>
            </div>
            <div className="flex items-center gap-2 whitespace-nowrap">
              <div className="w-4 h-4 rounded bg-[#D7FDED]"></div>
              <p className="text-sm font-medium text-gray-600">Order update</p>
            </div>
            <div className="flex items-center gap-2 whitespace-nowrap">
              <div className="w-4 h-4 rounded bg-[#39F6A3]"></div>
              <p className="text-sm font-medium text-gray-600">Address change</p>
            </div>
          </div>

          {/* Chart Container */}
          <div className="min-w-[700px] lg:h-[280px]">
            {loading ? (
              <div className="flex space-x-2">
                {[...Array(3)].map((_, index) => (
                  <div key={index} className="animate-pulse h-60 bg-gray-300 rounded-lg w-1/3"></div>
                ))}
              </div>
            ) : (
              <ResponsiveContainer width="100%" height={280}>
                <BarChart
                  data={yearlyData}
                  barGap={-10}
                  barCategoryGap={10}
                  margin={{ left: -30, right: 20, top: 10, bottom: 20 }}
                >
                  <CartesianGrid 
                    strokeDasharray="none"
                    vertical={false}
                    horizontal={true}
                    stroke="#E5E7EB"
                  />
                  <XAxis 
                    dataKey="month" 
                    axisLine={false}
                    tickLine={true}
                    stroke="black"
                    dy={5}
                    tick={{ fontSize: 16, fill: '#64748B' }}
                    interval={window.innerWidth < 768 ? 1 : 0}
                  />
                  <YAxis 
                    axisLine={false}
                    tickLine={true}
                    stroke="black"
                    domain={[0, 'auto']}
                    padding={{ top: 0, bottom: 0 }}
                    dx={-5}
                    tickFormatter={(value) => value.toFixed(0)}
                    tick={{ fill: '#64748B' }}
                  />
                  <Tooltip />
                  <Bar dataKey="Refund" fill="#000000" radius={[2, 2, 0, 0]} maxBarSize={35} />
                  <Bar dataKey="Order Update" fill="#D7FDED" radius={[2, 2, 0, 0]} maxBarSize={35} />
                  <Bar dataKey="Address Change" fill="#39F6A3" radius={[2, 2, 0, 0]} maxBarSize={35} />
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>

        {/* Additional Sections */}
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 px-6 mt-4">
          {/* Draft Status */}
          <div className="md:col-span-4 bg-[#F9F9F9] shadow-lg rounded-xl p-4 h-[300px] flex flex-col">
            <h2 className="text-base font-bold mb-2">Draft status</h2>
            <div className="h-[2px] w-full bg-gradient-to-r from-gray-400 to-transparent mb-3"></div>

            {loading ? (
              <div className="animate-pulse">
                <div className="h-4 bg-gray-300 rounded w-1/2 mb-2"></div>
                <div className="h-4 bg-gray-300 rounded w-1/3 mb-2"></div>
                <div className="h-4 bg-gray-300 rounded w-1/4"></div>
              </div>
            ) : (
              <>
                <div className="mb-3">
                  <div className="flex justify-between">
                    <p className="text-sm font-semibold">Total drafts</p>
                    <p className="text-sm font-bold">{draftStats.total_drafts || 0}</p>
                  </div>
                  <div className="h-2 bg-gray-200 rounded-lg mt-1 mb-3">
                    <div
                      className="h-2 rounded-lg"
                      style={{ backgroundColor: "#39F6A3", width: "100%" }}
                    ></div>
                  </div>
                </div>
                <div className="mb-2">
                  <div className="flex justify-between">
                    <p className="text-sm font-semibold">Pending drafts</p>
                    <p className="text-sm font-bold">{draftStats.pending_drafts || 0}</p>
                  </div>
                  <div className="h-2 bg-gray-200 rounded-lg mt-1 mb-4">
                    <div
                      className="h-2 rounded-lg"
                      style={{ backgroundColor: "#39F6A3", width: "30%" }}
                    ></div>
                  </div>
                </div>
                <div>
                  <div className="flex justify-between ">
                    <p className="text-sm font-semibold ">Approved drafts</p>
                    <p className="text-sm font-bold">{draftStats.drafts_sent || 0}</p>
                  </div>
                  <div className="h-2 bg-gray-200 rounded-lg mt-1 mb-4">
                    <div
                      className="h-2 rounded-lg"
                      style={{
                        backgroundColor: "#39F6A3",
                        width: `${
                          (draftStats.drafts_sent / draftStats.total_drafts) * 100|| 0
                        }%`,
                      }}
                    ></div>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Average Times Container */}
          <div className="md:col-span-3 flex flex-col gap-4 h-[300px]">
            {/* Average Generation Time */}
            <div className="bg-[#F9F9F9] shadow-lg rounded-xl p-4 flex-1 flex flex-col">
              <h2 className="text-base font-bold mb-2">Average generation time</h2>
              <div className="h-[2px] w-full bg-gradient-to-r from-gray-400 to-transparent mb-3"></div>

              {loading ? (
                <div className="animate-pulse h-8 bg-gray-300 rounded w-1/2"></div>
              ) : (
                <div className="flex-1 flex items-center">
                  <p className="text-2xl font-bold">
                    {averageTimes.average_generation_time
                      ? `${averageTimes.average_generation_time.toFixed(2)} sec`
                      : "N/A"}
                  </p>
                </div>
              )}
            </div>

            {/* Average Response Time */}
            <div className="bg-[#F9F9F9] shadow-lg rounded-xl p-4 flex-1 flex flex-col">
              <h2 className="text-base font-bold mb-2">Average response time</h2>
              <div className="h-[2px] w-full bg-gradient-to-r from-gray-400 to-transparent mb-3"></div>

              {loading ? (
                <div className="animate-pulse h-8 bg-gray-300 rounded w-1/2"></div>
              ) : (
                <div className="flex-1 flex items-center">
                  <p className="text-2xl font-bold">
                    {averageTimes.average_response_time
                      ? formatTime(averageTimes.average_response_time.toFixed(2))
                      : "N/A"}
                  </p>
                </div>
              )}
            </div>
          </div>

          {/* Top Customers */}
          <div className="md:col-span-5 bg-white shadow-lg rounded-xl p-4 h-[300px] flex flex-col">
            <h2 className="text-base font-bold mb-2">Frequent inquirers</h2>
            <div className="border-b-2 mb-3"></div>

            {loading ? (
              <div className="animate-pulse space-y-3">
                {[...Array(3)].map((_, index) => (
                  <div key={index} className="h-8 bg-gray-300 rounded"></div>
                ))}
              </div>
            ) : (
              <ul className="pr-4 space-y-3">
                {topCustomers.map((customer, index) => {
                  const extractEmail = (email) => {
                    const emailRegex = /<?([^<>]+@[^<>]+)>?/;
                    const match = email.match(emailRegex);
                    return match ? match[1] : "Invalid Email";
                  };

                  const email = extractEmail(customer.email_address);

                  return (
                    <li key={index} className="flex items-center border-b pb-3 last:border-b-0">
                      <div className="h-10 w-10 flex items-center justify-center bg-gray-200 rounded-full mr-4">
                        <span className="font-bold text-sm">
                          {email[0]?.toUpperCase() || "?"}
                        </span>
                      </div>
                      <div className="flex-1">
                        <p className="text-sm text-gray-500">{email}</p>
                      </div>
                      <div className="text-right ml-4">
                        <p className="text-sm text-gray-500">Queries</p>
                        <p className="text-lg font-bold text-black">
                          {customer.number_of_queries || 0}
                        </p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
